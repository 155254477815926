import Vue from "vue";

// eslint-disable-next-line
export function longDate(dateStr) {
  return new Intl.DateTimeFormat("pt-BR", { dateStyle: "long" }).format(new Date(dateStr));
}

export function dateFormat(dateStr) {
  return new Intl.DateTimeFormat("pt-BR").format(new Date(dateStr));
}

export function dateFormatParam(dateStr, format) {
  switch (format) {
    case "y-m-d":
      return dateStr.split("/").reverse().join("-");
    case "d/m/y":
      return dateStr.split("-").reverse().join("/");
    default:
      return dateStr.split("-").reverse().join("/");
  }
}

export function dateFormatHour(dateStr) {
  const timeOptions = {
    hourCycle: "h23",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  return new Intl.DateTimeFormat("pt-BR", {
    timeZone: "America/Fortaleza",
    ...timeOptions,
  }).format(new Date(dateStr));
}

export function formatHour(hourStr) {
  // '00:00:00' => '00:00'
  if (hourStr === null) return "- - -";
  const hourSplit = hourStr.split(":");
  const hours = hourSplit[0];
  const minutes = hourSplit[1];
  return `${hours}:${minutes}`;
}
export function dataAtualFormatada() {
  const data = new Date();
  const dia = data.getDate().toString();
  const diaF = dia.length === 1 ? `0${dia}` : dia;
  const mes = (data.getMonth() + 1).toString(); // +1 pois no getMonth Janeiro começa com zero.
  const mesF = mes.length === 1 ? `0${mes}` : mes;
  const anoF = data.getFullYear();
  return `${diaF}/${mesF}/${anoF}`;
}

Vue.filter("longDate", longDate);
Vue.filter("dateFormat", dateFormat);
Vue.filter("dateFormatParam", dateFormatParam);
Vue.filter("hour", formatHour);
Vue.filter("dateFormatHour", dateFormatHour);
Vue.filter("dataAtualFormatada", dataAtualFormatada);
