<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'alunos' })"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Aluno
        </h1>
      </v-col>
    </v-row>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
        <v-row>
          <!-- <v-col v-if="form.especial">
            <e-label>Laudo Infantil</e-label>
            <div class="dropboxStyles">
              <input
                id="fileUpload"
                class="input-file-upload"
                type="file"
                @change="selectFile"
              />
              <span v-if="!pdf" style="cursor: pointer">Upload</span>

              <span v-if="pdf">
                <v-chip
                  v-if="pdf"
                  class="ma-2"
                  color="green"
                  label
                  text-color="white"
                >
                  {{ pdf.target.files[0].name }}
                </v-chip>
              </span>

              <span v-if="form?.laudo && !pdf">
                <v-chip
                  v-if="form?.laudo && !pdf"
                  class="ma-2"
                  color="green"
                  label
                  text-color="white"
                >
                  {{ form?.laudo }}
                </v-chip>
              </span>

              <span v-if="pdf">
                <v-btn
                  v-if="pdf"
                  class="mx-2"
                  fab
                  dark
                  small
                  color="red"
                  @click="apagarLaudo"
                >
                  <v-icon dark> mdi-close </v-icon>
                </v-btn>
              </span>

              <span v-if="form.laudo">
                <v-btn
                  v-if="form.laudo"
                  class="mx-2"
                  fab
                  dark
                  small
                  color="red"
                  @click="apagarLaudoForm"
                >
                  <v-icon dark> mdi-close </v-icon>
                </v-btn>
              </span>

              <span v-if="form.laudo">
                <v-btn
                  v-if="form.laudo"
                  class="mx-2"
                  fab
                  dark
                  small
                  color="red"
                  @click="downloadLaudo"
                >
                  <v-icon dark> mdi-download </v-icon>
                </v-btn>
              </span>
            </div>
          </v-col> -->
        </v-row>

        <v-row>
          <v-col class="pt-0 pb-0" cols="8">
            <ValidationProvider name="Nome completo" rules="required" v-slot="{ errors }">
              <e-label>Nome completo</e-label>
              <v-text-field :error-messages="errors" v-model="form.nomecompleto" dense solo />
            </ValidationProvider>
          </v-col>

          <v-col class="pt-0 pb-0" cols="4">
            <e-label>Número do CPF</e-label>
            <v-text-field v-mask="'###.###.###-##'" v-model="form.cpf" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="4">
            <ValidationProvider name="Código do INEP" v-slot="{ errors }">
              <e-label>Identificação única(INEP)</e-label>
              <v-text-field
                :error-messages="errors"
                v-model="form.codigo_inep"
                :loading="pesquisandoINEP"
                dense
                solo
                v-mask="'############'"
                :required="!form.inep_status"
                :rules="[
                  !form.inep_status ? (v) => !!v || 'Preenchimento obrigatório do INEP' : '',
                ]"
                :disabled="form.inep_status"
              >
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col>
            <v-switch cols="2" v-model="form.inep_status" :input-value="form.codigo_inep !== ''">
              <template v-slot:label>Aluno sem INEP</template>
            </v-switch>
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <!-- <v-text-field :error-messages="errors" v-model="form.data_nascimento" dense solo /> -->
            <ValidationProvider name="Data de nascimento" rules="required" v-slot="{ errors }">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="data"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <e-label>Data de nascimento</e-label>
                  <v-text-field
                    v-model="form.data_nascimento"
                    persistent-hint
                    label="Escolha uma data"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    solo
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="data"
                  :error-messages="errors"
                  scrollable
                  placeholder="dd/mm/yyyy"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
                  <v-btn text color="primary" @click="$refs.dialog.save(data)"> OK </v-btn>
                </v-date-picker>
              </v-dialog>
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0" cols="12">
            <ValidationProvider name="Filiação 1" rules="required" v-slot="{ errors }">
              <e-label>Filiação 1</e-label>
              <v-text-field :error-messages="errors" v-model="form.filiacao1" dense solo />
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0" cols="12">
            <e-label>Filiação 2</e-label>
            <v-text-field v-model="form.filiacao2" dense solo />
          </v-col>

          <v-col class="pt-0 pb-0" cols="6">
            <e-label>Sexo </e-label>
            <e-autocomplete
              :items="$constants.sexoList"
              :return-object="false"
              :item-value="(value) => value.id"
              :item-text="(value) => value.descricao"
              v-model="form.sexo_id"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>Cor/Raça</e-label>
            <e-autocomplete
              :items="$constants.corOuRacaList"
              :return-object="false"
              v-model="form.cor_ou_raca"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>Nacionalidade</e-label>
            <e-autocomplete
              :items="$constants.nacionalidadeList"
              :return-object="false"
              :item-text="(value) => value"
              :item-value="(value) => value"
              v-model="form.naturalidade"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>País de nacionalidade</e-label>
            <v-text-field v-model="form.nacionalidade" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>UF de nascimento</e-label>
            <e-autocomplete
              :items="$constants.estadosList"
              :return-object="false"
              :item-text="(item) => item.codigo"
              :item-value="(item) => item.codigo"
              v-model="form.estadualidade"
              @change="(event) => atualizarEstado(event)"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>Município de nascimento</e-label>
            <e-autocomplete
              :items="municipiosNasc"
              :return-object="false"
              :item-text="(item) => item"
              :item-value="(item) => item"
              v-model="form.municipalidade"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>

          <v-col class="pt-0 pb-0" cols="12">
            <e-label
              >Aluno(a) com deficiência, transtorno do espectro autista ou altas
              habilidades/superdotação</e-label
            >
            <v-switch v-model="form.especial"></v-switch>
          </v-col>
          <template v-if="form.especial">
            <v-col cols="12">
              <h4>
                Tipo de deficiência, transtorno do espectro autista ou altas
                habilidades/superdotação
              </h4>
            </v-col>
            <v-col class="pt-0 pb-0" cols="4">
              <e-label>Deficiência</e-label>

              <e-autocomplete
                clearable
                :items="$constants.tipoDeDeficienciaList"
                :return-object="false"
                v-model="form.tipos_de_deficiencia"
                label="Selecione uma opção"
                multiple
                dense
                solo
              />
            </v-col>
            <v-col class="pt-0 pb-0" cols="4">
              <e-label>Transtorno do espectro autista</e-label>

              <e-autocomplete
                clearable
                :items="$constants.tipoDeTranstornoDoEspectroAutistaList"
                :return-object="false"
                v-model="form.tipos_de_transtornos"
                label="Selecione uma opção"
                multiple
                dense
                solo
              />
            </v-col>

            <v-col class="pt-0 pb-0" cols="4">
              <e-label>Altas habilidades/superdotação</e-label>

              <e-autocomplete
                clearable
                :items="$constants.tipoDeAltasHabilidadesList"
                :return-object="false"
                v-model="form.tipos_de_altas_habilidades"
                label="Selecione uma opção"
                multiple
                dense
                solo
              />
            </v-col>
          </template>

          <v-col v-if="form.especial" class="mb-6">
            <e-label>Laudo Infantil</e-label>
            <div class="dropboxStyles">
              <input id="fileUpload" class="input-file-upload" type="file" @change="selectFile" />
              <span v-if="!pdf" style="cursor: pointer">Upload</span>

              <span v-if="pdf">
                <v-chip v-if="pdf" class="ma-2" color="green" label text-color="white">
                  {{ pdf.target.files[0].name }}
                </v-chip>
              </span>

              <span v-if="form?.laudo && !pdf">
                <v-chip
                  v-if="form?.laudo && !pdf"
                  class="ma-2"
                  color="green"
                  label
                  text-color="white"
                >
                  {{ form?.laudo }}
                </v-chip>
              </span>

              <span v-if="pdf">
                <v-btn v-if="pdf" class="mx-2" fab dark small color="red" @click="apagarLaudo">
                  <v-icon dark> mdi-close </v-icon>
                </v-btn>
              </span>

              <span v-if="form.laudo">
                <v-btn
                  v-if="form.laudo"
                  class="mx-2"
                  fab
                  dark
                  small
                  color="red"
                  @click="apagarLaudoForm"
                >
                  <v-icon dark> mdi-close </v-icon>
                </v-btn>
              </span>

              <span v-if="form.laudo">
                <v-btn
                  v-if="form.laudo"
                  class="mx-2"
                  fab
                  dark
                  small
                  color="red"
                  @click="downloadLaudo"
                >
                  <v-icon dark> mdi-download </v-icon>
                </v-btn>
              </span>
            </div>
          </v-col>

          <v-col class="pt-0 pb-0" cols="12">
            <h4>
              Recursos para uso do(a) aluno(a) em sala de aula e para participação em avaliações do
              Inep (Saeb)
            </h4>
            <v-row class="">
              <v-col
                class="pt-0 pb-0"
                cols="4"
                v-for="(el, index) of $constants.recursosSaebList"
                :key="index"
              >
                <v-checkbox v-model="recursos_saeb[index]" :label="el" :value="el"></v-checkbox>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <h4>DOCUMENTO</h4>
          </v-col>
          <v-col class="pt-0 pb-0" cols="5">
            <e-label> Número de Identificação Social (NIS) </e-label>
            <v-text-field v-model="form.numero_nis" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="7">
            <e-label> Número da matrícula da certidão de nascimento (certidão nova) </e-label>
            <v-text-field v-model="form.matricula_certidao_de_nascimento" dense solo />
          </v-col>
          <v-col cols="12">
            <h4>DADOS SOBRE A ÁREA RESIDENCIAL DO(A) ALUNO(A)</h4>
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label> País de residência </e-label>
            <v-text-field v-model="form.pais_residencia" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="4">
            <e-label> CEP </e-label>
            <v-text-field v-mask="`#####-###`" v-model="form.cep" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="2">
            <e-label> UF </e-label>
            <e-autocomplete
              :items="$constants.estadosList"
              :return-object="false"
              :item-text="(item) => item.codigo"
              :item-value="(item) => item.codigo"
              v-model="form.uf_residencia"
              @change="(event) => atualizarEstadoRes(event)"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label> Município de residência </e-label>
            <e-autocomplete
              :items="municipiosRes"
              :return-object="false"
              :item-text="(item) => item"
              :item-value="(item) => item"
              v-model="form.municipio_residencia"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label> Zona de Residência </e-label>
            <e-autocomplete
              :items="$constants.zonaResidenciaList"
              :return-object="false"
              v-model="form.zona_residencia"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>Localização diferenciada de residência</e-label>
            <e-autocomplete
              :items="$constants.localizacaoDiferenciadaDeResidenciaList"
              :return-object="false"
              v-model="form.zona_diferenciada_residencia"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>Transporte escolar público</e-label>
            <v-switch
              v-model="form.utiliza_transporte_publico"
              color="primary"
              :label="form.utiliza_transporte_publico ? 'Utiliza' : 'Não utiliza'"
              hide-details
            ></v-switch>
          </v-col>
          <v-col cols="12">
            <v-btn
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
              block
              >Enviar Dados</v-btn
            >
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    "form.codigo_inep": function (newVal) {
      if (newVal) {
        this.form.inep_status = false;
      } else {
        this.form.inep_status = true;
      }
    },
    "form.inep_status": function (newVal) {
      if (newVal) {
        this.form.codigo_inep = null;
      }
    },
    form: {
      deep: true,
      handler() {
        if (this.nextInepTimeoutCall) {
          clearTimeout(this.nextInepTimeoutCall);
        }

        this.nextInepTimeoutCall = setTimeout(() => {
          this.verificarCodigoINEP();
        }, 500);
      },
    },
    data(value) {
      const data_old = new Intl.DateTimeFormat("pt-BR", {
        timeZone: "UTC",
      }).format(new Date(value));
      this.form.data_nascimento = data_old.replaceAll("/", "/");
    },
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
  },

  async mounted() {
    await this.loadFormData();
  },

  data() {
    return {
      pdf: null,
      submittingForm: false,
      recursos_saeb: [],
      form: {
        naturalidade: "Brasileira",
      },
      nextInepTimeoutCall: null,
      pesquisandoINEP: false,
      municipiosNasc: [],
      municipiosRes: [],
      edit: true,
      modal: false,
      data: null,
    };
  },

  methods: {
    atualizarEstado(value) {
      if (value) {
        this.municipiosNasc = this.$constants.municipios[value].cidades;
      }
    },

    atualizarEstadoRes(value) {
      if (value) {
        this.municipiosRes = this.$constants.municipios[value].cidades;
      }
    },

    apagarLaudo() {
      document.querySelector("#fileUpload").value = "";
      this.pdf = null;
    },

    apagarLaudoForm() {
      document.querySelector("#fileUpload").value = "";
      this.pdf = null;
      this.form.laudo = null;
    },

    selectFile(event) {
      if (this.pdf && event.target.files[0].type === "application/pdf") {
        this.pdf = null;
      }

      if (event.target.files[0].type === "application/pdf") {
        this.pdf = event;
        return;
      }

      this.$toast.danger("Formato de arquivo Inválido");
    },

    async pegarAluno() {
      try {
        const { aluno_id } = this.$route.params;
        const aluno = await this.$services.alunosService.pegarAluno(aluno_id);
        if (aluno.data_nascimento) {
          aluno.data_nascimento = aluno.data_nascimento.split("-").reverse().join("/");
        }
        this.$forceUpdate();
        return aluno;
      } catch (error) {
        return this.$handleError(error);
      }
    },

    async downloadLaudo() {
      try {
        const { aluno_id } = this.$route.params;
        const data = await this.$services.alunosService.downloadLaudo(aluno_id);
        return data;
      } catch (error) {
        return this.$handleError(error);
      }
    },

    async submitForm() {
      this.form.especial = Boolean(this.form.especial);
      this.form.recursos_saeb = this.recursos_saeb;
      this.submittingForm = true;

      try {
        this.form.data_nascimento = this.form.data_nascimento.split("/").reverse().join("-");
        if (this.editing) {
          await this.$services.alunosService.atualizarAluno(this.form);

          if (this.pdf) {
            const formData = new FormData();
            formData.append("file", this.pdf.target.files[0]);
            await this.$services.alunosService.uploadLaudo(formData, this.form.id);
          }

          this.$toast.success("Aluno atualizado com sucesso");
        } else {
          const alunoResponse = await this.$services.alunosService.criarAluno(this.form);

          if (this.pdf) {
            if (alunoResponse) {
              const formData = new FormData();
              formData.append("file", this.pdf.target.files[0]);
              await this.$services.alunosService.uploadLaudo(formData, alunoResponse.id);
            }
          }

          this.$toast.success("Aluno criado com sucesso");
        }
        this.$router.push({ name: "alunos" });
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
    },

    async loadFormData() {
      const aluno = await this.pegarAluno();
      if (aluno) {
        this.edit = false;
        if (aluno.codigo_inep) {
          this.form.inep_status = false;
        }
      }
      this.atualizarEstadoRes(aluno.uf_residencia);
      this.atualizarEstado(aluno.estadualidade);
      this.recursos_saeb = aluno.recursos_saeb ?? [];

      this.form = {
        ...aluno,
      };
    },

    async verificarCodigoINEP() {
      if (this.editing || !this.form.codigo_inep) return;
      this.pesquisandoINEP = true;
      try {
        const aluno = await this.$services.alunosService.getAlunoComINEP(this.form.codigo_inep);
        if (aluno) {
          if (aluno.configuracao_id !== this.user.configuracao_id) {
            this.$modals.warn({
              title: "Você gostaria de transferir esse(a) aluno(a)?",
              message: `Encontramos o(a) aluno(a) <strong>${aluno.nomecompleto}</strong> com INEP <strong>${aluno.codigo_inep}</strong> registrado em outra escola, você gostaria de transferí-lo para sua escola?`,
              buttons: [
                {
                  text: "Cancelar",
                  callback: () => {
                    this.$modals.close();
                  },
                  props: {
                    color: "warning",
                    small: true,
                  },
                },
                {
                  text: "Confirmar",
                  callback: async () => {
                    this.$modals.setOptions({ loading: true });
                    await this.$services.alunosService.atualizarAluno({
                      ...aluno,
                      configuracao_id: this.user.configuracao_id,
                    });
                    this.$modals.close();
                    this.$toast.success("Aluno transferido com sucesso!");
                    this.$router.push({ name: "alunos" });
                  },
                  props: {
                    color: "success",
                    // text: true,
                    small: true,
                  },
                },
              ],
            });
          } else {
            this.$modals.info({
              title: "Este(a) aluno(a) já está cadastrado na sua escola!",
              message: "Verificamos que este(a) aluno(a) já está cadastrado na sua escola, ",
              buttons: [
                {
                  text: "Confirmar",
                  callback: () => {
                    this.$modals.close();
                    this.$toast.info("Este(a) aluno(a) já está cadastrado na sua escola");
                    this.$router.push({ name: "alunos" });
                  },
                  props: {
                    color: "success",
                    small: true,
                  },
                },
              ],
            });
          }
        }
      } catch (error) {
        this.$handleError(error);
      }
      this.pesquisandoINEP = false;
    },
  },
};
</script>

<style scoped>
.dropboxStyles {
  outline: 2px dashed grey; /* the dash box */
  background-color: rgb(246, 255, 224);
  padding: 10px 10px;
  color: dimgray;
  cursor: pointer;
}
.input-file-upload {
  opacity: 0;
  width: 100%;
  height: 60px;
  position: absolute;
  cursor: pointer;
}
</style>
