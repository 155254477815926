<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="
              () =>
                $router.push({
                  name: 'auditoria',
                })
            "
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Auditoria de Aulas
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0 pb-0">
        <v-text-field
          v-model="search"
          class="my-2"
          append-icon="mdi-magnify"
          label="Pesquisar..."
          solo
          autofocus
          dense
          clearable
          single-line
          hide-details
        />
      </v-col>
      <v-col class="pt-0">
        <v-data-table
          :headers="table.headers"
          :items="aulasList"
          :items-per-page="15"
          :hide-default-footer="true"
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr
                color="primary"
                :class="aula.estaDeletado ? ['deleted'] : []"
                v-for="aula of items"
                :key="aula.id"
              >
                <td>{{ aula.id }}</td>
                <td>
                  {{ aula.turma?.descricao ? aula.turma?.descricao.toUpperCase() : "- - -" }}
                </td>
                <td>
                  {{ aula.professor?.nome ? aula.professor?.nome.toUpperCase() : "- - -" }}
                </td>
                <td>
                  {{
                    aula.disciplina?.descricao ? aula.disciplina?.descricao.toUpperCase() : "- - -"
                  }}
                </td>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="blue-grey lighten-3"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => verHistorico(aula)"
                      >
                        <v-icon small>fa fa-eye </v-icon>
                      </v-btn>
                    </template>
                    <span>Ver Histórico de Alterações</span>
                  </v-tooltip>
                  <!-- <v-tooltip v-if="aula.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="info"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => recuperarAula(aula)"
                      >
                        <v-icon small>fa fa-sync </v-icon>
                      </v-btn>
                    </template>
                    <span>Recuperar aula</span>
                  </v-tooltip> -->
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>

        <e-paginate
          :current_page="paginate.current_page"
          :last_page="paginate.last_page"
          :pageNumber="pageNumber"
          @changeActionResponse="listarAulas"
          :search="search"
        ></e-paginate>
      </v-col>
    </v-row>

    <e-drawer-ver-auditoria
      v-model="exibir"
      :auditated_model="aula"
      :auditated_descripition="$route.name"
    >
    </e-drawer-ver-auditoria>
  </main-template>
</template>

<script>
import { dataTableFilter } from "@/plugins/searchQuery";
import Aula from "@/Models/Aula";

export default {
  computed: {
    //
  },
  data() {
    return {
      aula: null,
      exibir: false,
      dataTableFilter,
      search: null,
      pageNumber: 0,
      paginate: {
        current_page: 0,
        last_page: 0,
      },
      aulasList: [],
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Turma", value: "turma.descricao" },
          { text: "Professor", value: "instrutor.nome" },
          { text: "Disciplina", value: "disciplina.descricao" },
          { text: "Ações", value: "actions", sortable: false },
        ],
      },
    };
  },
  async mounted() {
    this.aulas = true;
    this.$loaderService.open("Carregando aula");
    await this.listarAulas(this.pageNumber);
    this.carregandoGestoes = false;
    this.$loaderService.close();
  },
  watch: {
    aulas(value) {
      if (value && this.aulasList.length === 0) {
        this.$loaderService.open("Carregando aula");
      } else {
        this.$loaderService.close();
      }
    },
    async search(val) {
      if (val?.length > 1) {
        await this.listarAulas(0, val);
      }
      if (!val) {
        await this.listarAulas(0);
      }
    },
  },
  methods: {
    async listarAulas(pageNumber, query = null) {
      this.aulas = true;
      try {
        const response = await this.$services.aulasService.AuditoriaPaginate(pageNumber, query);
        this.aulasList = response.data.map((aula) => new Aula(aula));
        this.paginate = response;
      } catch (error) {
        this.$handleError(error);
      }
      this.aulas = false;
    },
    verHistorico(aula) {
      this.exibir = !this.exibir;
      this.aula = aula;
    },
    recuperarAula(aula) {
      this.$modals.warn({
        title: "Atenção!",
        message: "Tem certeza que deseja recuperar esta aula?",
        confirmationCode: aula.id,
        confirmationMessage: `Por favor, digite <strong>${aula.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Recuperando a Aula");
              try {
                await this.$services.aula.recuperarAula(aula);
                this.$toast.success("Aula recuperada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
  },
};
</script>

<style></style>
