<template>
  <main-template>
    <v-row>
      <v-col cols="12">
        <h1>
          <v-btn color="primary" x-small text @click="goBack">
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Lançamento relatório - PEI
        </h1>
      </v-col>

      <v-col cols="12">
        <p>
          <v-chip color="orange lighten-1 white--text"
            >ALUNO: {{ aluno ? aluno.nomecompleto : "- - -" }}
          </v-chip>
        </p>
        <!-- <v-date-picker elevation="2" full-width  v-model="selectedDate" label="Selecione uma data"></v-date-picker> -->

        <v-dialog ref="dialog" v-model="modal" width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="selectedDate"
              label="Selecione uma data"
              solo
              readonly
              v-bind="attrs"
              v-on="on"
              append-icon="mdi-calendar"
              @click:append="modal = !modal"
            ></v-text-field>
          </template>
          <v-date-picker v-model="selectedDate" scrollable>
            <v-spacer></v-spacer>
            <!-- <v-btn text color="primary" @click="cancel"> Cancelar </v-btn> -->
            <!-- <v-btn color="red" text @click="modal = !modal"> Fechar </v-btn> -->
            <v-btn text color="primary" @click="$refs.dialog.save(selectedDate)"> OK </v-btn>
          </v-date-picker>
        </v-dialog>

        <v-alert
          text
          border="left"
          color="orange"
          icon="warning"
          class="text-caption"
          v-if="!selectedDate"
        >
          <span style="font-size: 1.2em">
            Atenção, por favor selecione uma data antes de prosseguir.
          </span>
        </v-alert>

        <v-list color="grey lighten-3" v-if="form[0]">
          <v-list-group :value="true" v-for="pergunta in form" :key="pergunta.id" no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="pergunta.subtitulo"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item v-for="child in pergunta.children" :key="child.id">
              <v-list-item-content>
                <p>{{ child.questao }}</p>
                <v-textarea solo v-model="child.resposta" label="Resposta"></v-textarea>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-col>
      <v-col cols="12">
        <v-btn
          :disabled="!form[0]"
          :loading="submittingForm"
          @click="() => submitForm()"
          color="success"
          type="submit"
          >Enviar Dados</v-btn
        >
      </v-col>
    </v-row>
  </main-template>
</template>

<script>
import { dataAtualFormatada } from "../../plugins/filters";

export default {
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      table: {
        headers: [
          { text: "Disciplina", value: "descricao" },
          { text: "ALCANÇADO", value: "codigo" },
          { text: "EM PROCESSO", value: "codigo_inep" },
          { text: "NÃO", value: "nomecompleto" },
          { text: "NÃO AVALIADO", value: "actions", sortable: false },
        ],
      },
      form: [],
      submittingForm: false,
      modal: false,
      aluno: null,
      selectedDate: null,
      disciplina_id: 3, // Define o ID da disciplina diretamente como 3
    };
  },
  methods: {
    async loadData() {
      const { gestaoId } = this.$route.params;
      const data = await this.$services.gestoesDeAulasAuxiliaresService.listarDisciplinas(
        parseInt(gestaoId, 10)
      );
      this.aluno = data.data.aluno;
      this.selectedDate = dateFormatParam(dataAtualFormatada(), "y-m-d");
      // console.log(this.selectedDate);
      // await this.getPerguntas();
    },
    async getPerguntas() {
      this.form = [];
      const { gestaoId } = this.$route.params;
      const data = await this.$services.gestoesDeAulasAuxiliaresService.listarPerguntas(
        this.disciplina_id, // Usa o ID da disciplina definido acima
        gestaoId,
        this.selectedDate
      );
      this.form = data.data;
      // console.log(this.form);
    },
    async submitForm() {
      if (!this.form[0].children) return;
      const { gestaoId } = this.$route.params;
      await this.$services.gestoesDeAulasAuxiliaresService.salvar(
        this.form,
        this.disciplina_id,
        gestaoId,
        this.selectedDate
      );
      this.$toast.success("Relatório salvo com sucesso");
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  watch: {
    selectedDate: {
      async handler() {
        await this.getPerguntas();
        // console.log(this.selectedDate);
      },
      immediate: true,
    },
  },
};
</script>
