import Axios from "@/plugins/Axios";

export class PlanosBimestraisService {
  async planoBimestral(gestaoDeAulaId, bimestre, disciplinaId) {
    const response = await Axios().get(
      `planos-bimestrais/plano-bimestral/${gestaoDeAulaId}/${bimestre}/${disciplinaId}`
    );
    return response.data;
  }

  async pegarPlanos(gestaoDeAulaId, disciplinaId) {
    const response = await Axios().get(
      `planos-bimestrais/pegar-planos/${gestaoDeAulaId}/${disciplinaId}`
    );
    return response.data;
  }

  async createOrUpdate(plano) {
    const response = await Axios().post(`planos-bimestrais/criar-plano-bimestral`, {
      plano,
    });
    return response.data;
  }

  async salvarPlanos(planos, gestaoDeAulaId, disciplinaId) {
    const response = await Axios().post(
      `planos-bimestrais/salvar-planos/${gestaoDeAulaId}/${disciplinaId}`,
      {
        planos,
      }
    );
    return response.data;
  }

  async planoBimestralPDF(gestaoDeAulaId, bimestre, disciplinaId, file) {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await Axios().post(
        `planos-bimestrais/uploadPdf/${gestaoDeAulaId}/${bimestre}/${disciplinaId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const resultData = response.data ?? response;
      if (resultData) {
        return resultData;
      }
      // console.warn("Upload bem-sucedido, mas sem resposta significativa do servidor.");
      return null;
    } catch (error) {
      // console.error("Erro ao enviar o arquivo:", error);
      // throw error;
    }
  }

  async planoBimestralDownload(gestaoDeAulaId, bimestre, disciplinaId) {
    const response = await Axios()
      .get(`planos-bimestrais/downloadPdf/${gestaoDeAulaId}/${bimestre}/${disciplinaId}`, {
        responseType: "blob",
      })
      .then((response) => {
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(response.data);
        window.open(a.href);
      });

    return response;
  }
}
export default new PlanosBimestraisService();
