<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ split(auditated_descripition) }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-expansion-panels class="my-3" inset>
          <v-expansion-panel>
            <v-expansion-panel-header color="green">
              Estado atual do registro
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-alert text border="left" type="info" color="green">
                <v-row justify="space-between">
                  <v-col
                    v-for="(model, index) in auditated_model"
                    :key="index"
                    cols="6"
                    md="4"
                    lg="3"
                    xl="2"
                  >
                    <span v-if="index !== 'audits'" class="text-body-1">
                      {{ index.split("_").join(" ").toUpperCase() }}:
                      {{ model ?? "---" }}
                    </span>
                  </v-col>
                </v-row>
              </v-alert>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels class="my-3" inset>
          <v-expansion-panel
            v-for="(audit, index) in auditated_model?.audits"
            :key="index + audit.id"
          >
            <v-expansion-panel-header :color="eventColor[audit.event]">
              ID da Auditoria: {{ audit.id }} - {{ events[audit.event] }} por
              {{ audit.user?.name }} ( {{ audit.user_id ?? "SEM USUÁRIO" }} ) em
              {{ dateFormat(audit.created_at) }} às
              {{ dateFormatHour(audit.created_at) }}</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-alert text border="left" type="info" color="green">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-alert color="primary">
                      Valores anteriores ao evento de '{{ events[audit.event] }}'':
                    </v-alert>
                  </v-col>
                  <v-col
                    v-for="(old, index) in audit.old_values"
                    :key="index + '_old'"
                    cols="12"
                    md="6"
                    lg="4"
                    class="py-0"
                  >
                    <v-alert dense text color="blue" class="text-body-1">
                      {{ split(index) }}: {{ old ?? "---" }}
                    </v-alert>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-alert color="primary">
                      Valores após ao evento de '{{ events[audit.event] }}'':
                    </v-alert>
                  </v-col>
                  <v-col
                    v-for="(new_, index) in audit.new_values"
                    :key="index + '_new'"
                    cols="12"
                    md="6"
                    lg="4"
                    class="py-0"
                  >
                    <v-alert dense text color="blue" class="text-body-1">
                      {{ split(index) }}: {{ new_ ?? "---" }}
                    </v-alert>
                  </v-col>
                </v-row>
              </v-alert>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { dateFormat, dateFormatHour } from "../../plugins/filters";

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    auditated_model: {
      type: Object,
      required: true,
    },
    auditated_descripition: {
      type: String,
      required: true,
    },
  },
  watch: {
    value() {
      this.dialog = this.value;
    },
    async dialog() {
      this.$emit("input", this.dialog);
      if (this.dialog) {
        await this.restaurarAudit();
      }
    },
  },
  computed: {},
  data() {
    return {
      dialog: false,
      notifications: false,
      sound: true,
      dateFormatHour,
      dateFormat,
      widgets: false,
      events: {
        created: "Criado",
        updated: "Atualizado",
        deleted: "Deletado",
        restored: "Restaurados",
      },
      eventColor: {
        created: "green",
        updated: "primary",
        deleted: "red",
        restored: "blue",
      },
    };
  },
  methods: {
    async restaurarAudit() {
      //   try {
      //     const response = await this.$services.gestoesDeAulasService.restaurarAudit(
      //       this.gestao_id,
      //       this.bimestre
      //     );
      //     this.plano = response.historicoLancamento
      //       ? response.historicoLancamento
      //       : {
      //           audits: [],
      //         };
      //   } catch (error) {
      //     this.$handleError(error);
      //   }
    },
    split(texto) {
      return texto
        .split(".")
        .map((parte) => parte.split("-").join(" "))
        .join(" - ")
        .toUpperCase();
    },
  },
};
</script>

<style lang="scss" scoped></style>
