<template>
  <main-template>
    <v-row>
      <v-col>
        <span class="text-h4">Módulos Auditados</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0 pb-0">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisar..."
          solo
          autofocus
          dense
          single-line
          clearable
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="4"
        class="mt-2 d-flex flex-grow-0 flex-shrink-1 align-stretch"
        v-for="modulo in modulosList.filter((m) => m.implemented)"
        :key="modulo.id"
      >
        <v-card ripple style="min-width: 100%" @click="acessarDados(modulo.routeName)">
          <v-card-title>
            <span class="">{{ modulo.name }}</span>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </main-template>
</template>

<script>
export default {
  data() {
    return {
      search: null,
      modulosList: this.$constants.modulosAuditoria,
    };
  },
  watch: {
    async search(val) {
      if (val?.length > 1) {
        this.modulosList = this.modulosList.filter((m) =>
          m.name.toLowerCase().includes(val.toLowerCase())
        );
      }
      if (!val) {
        this.modulosList = this.$constants.modulosAuditoria;
      }
    },
  },
  methods: {
    acessarDados(routeName) {
      this.$router.push({ name: routeName });
    },
  },
};
</script>

<style></style>
