<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="
              () =>
                $router.push({
                  name: 'auditoria',
                })
            "
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Auditoria de Planos Bimestrais
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-form>
          <v-card>
            <div class="text-start py-2 px-4 text-button">Pesquisar planos de aulas</div>

            <v-divider class="theme--light"></v-divider>
            <v-row class="px-4 mt-4">
              <v-col cols="12">
                <v-text-field
                  v-model="professor"
                  label="NOME ou CPF Professor"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="d-flex justify-space-between mb-6 px-4">
              <v-col cols="12" sm="6" md="4">
                <v-autocomplete
                  :items="disciplinas"
                  :return-object="false"
                  v-model="disciplina"
                  label="Escolha uma disciplina"
                  item-text="descricao"
                  item-value="descricao"
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-autocomplete
                  :items="turmas"
                  :return-object="false"
                  v-model="turma"
                  label="Escolha uma turma"
                  item-text="descricao"
                  item-value="descricao"
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6" md="4" s>
                <v-autocomplete
                  :items="bimestres"
                  :return-object="false"
                  v-model="bimestre"
                  label="Escolha uma bimestre"
                  item-text="descricao"
                  item-value="id"
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="12" md="12" class="mb-5">
                <v-btn color="primary" block @click="() => limparCampos()">Limpar filtros</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-form>
      </v-col>
      <!-- <v-col cols="12" class="pt-0 pb-0">
        <v-text-field
          v-model="search"
          class="my-2"
          append-icon="mdi-magnify"
          label="Pesquisar..."
          solo
          autofocus
          dense
          clearable
          single-line
          hide-details
        />
      </v-col> -->
      <v-col class="pt-0">
        <v-data-table
          :headers="table.headers"
          :items="planosList"
          :items-per-page="15"
          :hide-default-footer="true"
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr
                color="primary"
                :class="plano.estaDeletado ? ['deleted'] : []"
                v-for="plano of items"
                :key="plano.id"
              >
                <td>{{ plano.id }}</td>
                <td>{{ plano.gestao_de_aula.professor.nome.toUpperCase() }}</td>
                <td>{{ plano.gestao_de_aula.turma.descricao.toUpperCase() }}</td>
                <td>
                  <e-situacao-plano
                    :plano="plano"
                    @update="(e) => [carregarDadosAoAtualizarStatus(e)]"
                  />
                </td>
                <td>
                  {{ plano.bimestre.descricao.toUpperCase() }}
                </td>
                <td>
                  {{
                    plano.gestao_de_aula.disciplina?.descricao
                      ? plano.gestao_de_aula.disciplina?.descricao.toUpperCase()
                      : "- - -"
                  }}
                </td>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="blue-grey lighten-3"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => verHistorico(plano)"
                      >
                        <v-icon small>fa fa-eye </v-icon>
                      </v-btn>
                    </template>
                    <span>Ver Histórico de Alterações</span>
                  </v-tooltip>
                  <!-- <v-tooltip v-if="plano.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="info"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => recuperarPlano(plano)"
                      >
                        <v-icon small>fa fa-sync </v-icon>
                      </v-btn>
                    </template>
                    <span>Recuperar plano</span>
                  </v-tooltip> -->
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>

        <e-paginate
          :current_page="paginate.current_page"
          :last_page="paginate.last_page"
          :pageNumber="pageNumber"
          @changeActionResponse="listarPlanos"
          :search="search"
        ></e-paginate>
      </v-col>
    </v-row>

    <e-drawer-ver-auditoria
      v-model="exibir"
      :auditated_model="plano ?? {}"
      :auditated_descripition="$route.name"
    >
    </e-drawer-ver-auditoria>
  </main-template>
</template>

<script>
import { dataTableFilter } from "@/plugins/searchQuery";
import Plano from "@/Models/Plano";

export default {
  data() {
    return {
      plano: null,
      exibir: false,
      dataTableFilter,
      search: "",
      pageNumber: 0,
      paginate: {
        current_page: 0,
        last_page: 0,
      },
      disciplinas: [],
      disciplina: "",
      bimestres: [],
      bimestre: "",
      turmas: [],
      turma: "",
      professor: "",
      planosList: [],
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Professor", value: "gestao_de_aula.professor.nome" },
          { text: "Turma", value: "gestao_de_aula.turma.descricao" },
          { text: "Status", value: "status" },
          { text: "Bimestre", value: "bimestre" },
          { text: "Disciplina", value: "gestao_de_aula.disciplina.descricao" },
          { text: "Ações", value: "actions", sortable: false },
        ],
      },
    };
  },
  watch: {
    planos(value) {
      if (value && this.planosList.length === 0) {
        this.$loaderService.open("Carregando plano");
      } else {
        this.$loaderService.close();
      }
    },
    async search(val) {
      console.log("chamou o search: ", val);
      if (val?.length > 1) {
        await this.listarPlanos(0, val);
      }
      if (!val) {
        await this.listarPlanos(0);
      }
    },
    async professor(value) {
      value = this.formatarCPf(value);
      this.professor = value;
      await this.listarPlanos(0);
    },
    async turma(value) {
      this.turma = value;
      await this.listarPlanos(0);
    },
    async disciplina(value) {
      this.disciplina = value;
      await this.listarPlanos(0);
    },
    async bimestre(value) {
      this.bimestre = value;
      await this.listarPlanos(0);
    },
  },
  async mounted() {
    this.planos = true;
    this.$loaderService.open("Carregando plano");
    await this.loadData();
    this.carregandoGestoes = false;
    this.$loaderService.close();
  },

  methods: {
    async loadData() {
      await this.listarPlanos(this.pageNumber);
      await this.listarTurmas();
      await this.listarDisciplinas();
      await this.listarBimestres();
    },
    async carregarDadosAoAtualizarStatus() {
      // try {
      //   this.listarPlanos(this.paginate.current_page);
      // } catch (error) {
      //   this.$handleError(error);
      // }
      this.$toast.danger("Funcionalidade não implementada no módulo de auditoria.");
    },
    async listarPlanos(pageNumber, query = null) {
      this.planos = true;
      try {
        const response = await this.$services.planosService.auditoriaPaginate(
          pageNumber,
          query,
          this.professor,
          this.turma,
          this.disciplina,
          this.bimestre
        );
        this.planosList = response.data.map((plano) => new Plano(plano));
        this.paginate = response;
      } catch (error) {
        this.$handleError(error);
      }
      this.planos = false;
    },
    async listarDisciplinas() {
      try {
        const { data } = await this.$services.planosService.disciplinas();

        this.disciplinas = data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async listarTurmas() {
      try {
        const { data } = await this.$services.planosService.turmas();
        const result = data.map((t) => {
          const local = { ...t };
          return (local.descricao = `${t.descricao} (${t.franquia.descricao})`);
        });
        this.turmas = result;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async listarBimestres() {
      try {
        const { data } = await this.$services.planosService.bimestres();
        this.bimestres = data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    verHistorico(plano) {
      this.exibir = !this.exibir;
      this.plano = plano;
    },
    formatarCPf(value) {
      value = value.replace("-", "");
      value = value.replace(".", "");
      return value;
    },
    limparCampos() {
      this.professor = "";
      this.turma = "";
      this.disciplina = "";
      this.mes = "";
      this.bimestre = "";
    },
    recuperarPlano(plano) {
      this.$modals.warn({
        title: "Atenção!",
        message: "Tem certeza que deseja recuperar esta plano?",
        confirmationCode: plano.id,
        confirmationMessage: `Por favor, digite <strong>${plano.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Recuperando a Plano");
              try {
                await this.$services.planosService.recuperarPlano(plano);
                this.$toast.success("Plano recuperada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
  },
};
</script>

<style></style>
